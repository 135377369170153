import React from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';

export function MenuItem({
  onPress,
  text,
  showExclamation,
}: {
  onPress: () => void;
  text: string;
  showExclamation?: boolean;
}) {
  return (
    <Pressable onPress={onPress} style={styles.button}>
      <View style={styles.buttonBody}>
        <Text selectable={false} style={styles.text}>
          {text}
        </Text>
        {showExclamation && (
          <View style={styles.exclamationContainer}>
            <Text style={styles.exclamationText}>!</Text>
          </View>
        )}
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    alignSelf: 'center',
    paddingHorizontal: 10,
    paddingVertical: 10,
    marginTop: 5,
    backgroundColor: '#333333',
    borderRadius: 8,
    borderColor: 'orange',
    borderWidth: 2,
    flex: 1,
    alignItems: 'center',
    marginHorizontal: 5,
  },
  text: {fontSize: 14, fontWeight: '900', color: 'white'},
  buttonBody: {flexDirection: 'row'},
  exclamationContainer: {
    backgroundColor: 'red',
    width: 16,
    height: 16,
    borderRadius: 8,
    alignItems: 'center',
    marginLeft: 4,
  },
  exclamationText: {color: 'white', fontSize: 14, fontWeight: '900'},
});
