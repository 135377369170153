import React, {useEffect} from 'react';
import {StyleSheet, Text} from 'react-native';
import Animated, {
  cancelAnimation,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSequence,
  withTiming,
} from 'react-native-reanimated';
import {useInventory} from '../state/state';
import {getSlotCoordinates} from './Draggable';

export function Finger() {
  const layout = useInventory(state => state.layout);
  const size = useInventory(state => state.size);

  const tutorialState = useInventory(state => state.tutorialState);
  let startPosition = getSlotCoordinates(1, layout, size).x;

  if (tutorialState === 1) {
    startPosition = getSlotCoordinates(2, layout, size).x;
  }
  const offset = useSharedValue(0);
  const scale = useSharedValue(1);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{translateX: offset.value}, {scale: scale.value}],
    };
  });

  useEffect(() => {
    offset.value = withRepeat(
      withSequence(
        withTiming(0, {duration: 1000}),
        withTiming(-72, {duration: 1000}),
        withTiming(-72, {duration: 500}),
        withTiming(-1000, {duration: 0}),
        withTiming(-1000, {duration: 500}),
      ),
      -1,
    );
    scale.value = withRepeat(
      withSequence(
        withTiming(1, {duration: 250}),
        withTiming(0.8, {duration: 500}),
        withTiming(0.8, {duration: 1750}),
        withTiming(1, {duration: 500}),
      ),
      -1,
    );
    return () => {
      cancelAnimation(offset);
      cancelAnimation(scale);
      scale.value = 1;
      offset.value = 0;
    };
  }, [offset, scale, tutorialState]);

  return (
    <Animated.View
      pointerEvents="none"
      style={[
        styles.container,
        {
          top: layout.y + 30,
          left: startPosition,
        },
        animatedStyles,
      ]}>
      <Text style={styles.finger}>👆</Text>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  finger: {fontSize: 48, color: 'white'},
  container: {position: 'absolute'},
});
