import React from 'react';
import {View} from 'react-native';
import {useInventory} from '../state/state';
import {Draggable} from './Draggable';

export function Items() {
  const items = useInventory(state => state.items);
  const {rows, columns} = useInventory(state => state.size);

  return (
    <View style={{position: 'absolute', top: 0}}>
      {Array.from({length: rows * columns}).map((_, i) => {
        const item = items[i];
        if (item) {
          return <Draggable key={item.id} item={item} />;
        }
      })}
    </View>
  );
}
