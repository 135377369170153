import React, {useEffect} from 'react';
import {
  Platform,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  View,
} from 'react-native';
import {Header} from './components/Header';
import {Inventory} from './components/Inventory';
import 'react-native-gesture-handler';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import ProgressBar from './components/ProgressBar';
import {Upgrade} from './components/Upgrade/UpgradeModal';
import {Collection} from './components/Collection';

import {Ads} from './core/Ads';
import {WelcomeBack} from './components/WelcomeBack';
import {Settings} from './components/Settings';
import {CloverModal} from './components/Clover/CloverModal';
import {BannerAd} from './core/Ads/BannerAd';
import {useInventory} from './state/state';
import {DailyReward} from './components/DailyReward';

Ads.init();

export default function App() {
  const canShowAds = useInventory(state => state.canShowAds);
  useEffect(() => {
    if (Platform.OS !== 'web') {
      const RNBootSplash = require('react-native-bootsplash');
      RNBootSplash.hide({fade: true}); // fade with 220ms default duration
    }
  }, []);

  return (
    <GestureHandlerRootView style={styles.gestureHandlerRootView}>
      <StatusBar barStyle={'light-content'} />
      <View style={styles.mainContainer}>
        <Settings />
        <Header />
        <Inventory />
        <View style={styles.progressBarContainer}>
          <ProgressBar />
        </View>
        <View style={styles.menu}>
          <Upgrade />
          <DailyReward />
          <Collection />

          <WelcomeBack />
          <CloverModal />
        </View>
        {canShowAds && (
          <SafeAreaView>
            <BannerAd />
          </SafeAreaView>
        )}
      </View>
    </GestureHandlerRootView>
  );
}

const styles = StyleSheet.create({
  progressBarContainer: {marginTop: 20},
  gestureHandlerRootView: {flex: 1, backgroundColor: '#111111'},
  menu: {
    flexDirection: 'row',
    flex: 1,
    marginHorizontal: 20,
    alignSelf: 'center',
    alignItems: 'center',
    maxWidth: 400,
    ...Platform.select({web: {marginBottom: 20}}),
  },
  mainContainer: {
    flex: 1,
    backgroundColor: '#111111',
    maxHeight: 960,
  },
  row: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginVertical: 2.5,
    gap: 5,
  },
  dropZone: {
    height: 200,
    backgroundColor: '#00334d',
  },
  text: {
    marginTop: 25,
    marginLeft: 5,
    marginRight: 5,
    textAlign: 'center',
    color: '#fff',
    fontSize: 25,
    fontWeight: 'bold',
  },
});
