import React, {PropsWithChildren} from 'react';
import {StyleSheet, View} from 'react-native';

const styles = StyleSheet.create({
  slot: {
    width: 70,
    aspectRatio: 1,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#222222',
    borderColor: '#eeeeee',
  },
});

export function Slot({children}: PropsWithChildren) {
  return <View style={styles.slot}>{children}</View>;
}
