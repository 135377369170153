import React from 'react';
import {StyleSheet, View} from 'react-native';
import {InventorySize} from '../../core/Inventory';

const styles = StyleSheet.create({
  icon: {width: 40, height: 40},
  row: {flexDirection: 'row', alignItems: 'center'},

  inventoryIconItem: {
    backgroundColor: 'white',
    height: 5,
    width: 5,
    margin: 2,
    borderRadius: 1,
  },
  inventorySizeContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: 'white',
  },
});

export function InventorySizeIcon({size}: {size: InventorySize}) {
  return (
    <View style={[styles.icon, styles.inventorySizeContainer]}>
      {Array.from({length: size.rows}).map((_, i) => (
        <View key={`row-${i}`} style={styles.row}>
          {Array.from({length: size.columns}).map((__, r) => (
            <View key={`column-${r}`} style={styles.inventoryIconItem} />
          ))}
        </View>
      ))}
    </View>
  );
}
