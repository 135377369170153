import React, {
  useEffect,
  useRef,
  PropsWithChildren,
  useMemo,
  useState,
} from 'react';
import {
  View,
  Animated,
  PanResponder,
  PanResponderGestureState,
  Image,
  LayoutRectangle,
  Text,
  GestureResponderEvent,
} from 'react-native';
import {InventorySize} from '../core/Inventory';

import {WeaponMap} from '../core/Weapons';
import {InventoryItem, useInventory} from '../state/state';
import {formatNumber} from '../utils/formatNumber';

type DraggableProps = PropsWithChildren & {item: InventoryItem};

export function Draggable({item}: DraggableProps) {
  const layout = useInventory(state => state.layout);
  const size = useInventory(state => state.size);

  const moveItem = useInventory(state => state.moveItem);
  const canMoveItem = useInventory(state => state.canMoveItem);
  const tutorialState = useInventory(state => state.tutorialState);

  const isEnabled =
    (tutorialState === 0 && item.position === 1) ||
    (tutorialState === 1 && item.position === 2) ||
    (tutorialState === 2 && item.position === 1) ||
    tutorialState >= 3;

  const val = useRef<{
    x: number;
    y: number;
  }>(getSlotCoordinates(item.position, layout, size));
  const pan = useRef<Animated.ValueXY>(
    new Animated.ValueXY(getSlotCoordinates(item.position, layout, size)),
  ).current;
  const scale = useAnimatedValue(0, {useNativeDriver: false});
  const [zIndex, setZIndex] = useState(1);

  const panResponder = useMemo(
    () =>
      PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onPanResponderGrant: () => {
          if (!isEnabled) {
            return;
          }
          console.log('onPanResponderGrant', {setOffset: val.current});
          pan.setOffset({
            x: val.current?.x || 0,
            y: val.current?.y || 0,
          });
          pan.setValue({x: 0, y: 0});
          setZIndex(999);
        },
        onPanResponderMove: (
          e: GestureResponderEvent,
          gestureState: PanResponderGestureState,
        ) => {
          if (!isEnabled) {
            return;
          }
          return Animated.event([null, {dx: pan.x, dy: pan.y}], {
            useNativeDriver: false,
          })(e, gestureState);
        },
        onPanResponderRelease: (e, gesture) => {
          if (!isEnabled) {
            return;
          }
          console.log('onPanResponderRelease');
          const slot = getSlotNumber(
            gesture.moveX,
            gesture.moveY,
            layout,
            size,
          );
          if (
            isDropArea(gesture, layout) &&
            slot > -1 &&
            slot !== item.position &&
            canMoveItem(item.position, slot)
          ) {
            moveItem(item.position, slot);
          } else {
            Animated.timing(pan, {
              toValue: {x: 0, y: 0},
              duration: 100,
              useNativeDriver: false,
            }).start();
          }
          setZIndex(1);
        },
      }),
    [moveItem, canMoveItem, pan, item.position, layout, size, isEnabled],
  );

  useEffect(() => {
    pan.addListener(value => (val.current = value));

    return () => {
      pan.removeAllListeners();
    };
  }, [pan]);

  useEffect(() => {
    // Scale animation
    Animated.sequence([
      /*       Animated.timing(scale, {
        useNativeDriver: false,
        duration: 50,
        toValue: 0,
      }), */
      Animated.timing(scale, {
        useNativeDriver: false,
        duration: 100,
        toValue: 1.2,
      }),
      Animated.timing(scale, {
        useNativeDriver: false,
        duration: 100,
        toValue: 1,
      }),
    ]).start();
  }, [item.type, scale]);

  useEffect(() => {
    // Replace animation
    pan.flattenOffset();
    Animated.timing(pan, {
      toValue: getSlotCoordinates(item.position, layout, size),
      duration: 100,
      useNativeDriver: false,
    }).start();
  }, [pan, item.position, layout, size]);

  const panStyle = {
    transform: [...pan.getTranslateTransform(), {scale}],
  };
  return (
    <View style={{position: 'absolute', left: 0, top: 0, zIndex}}>
      <Animated.View {...panResponder.panHandlers} style={[panStyle]}>
        <Image source={getImageSource(item)} style={{width: 50, height: 50}} />
        <Text selectable={false} style={{color: 'white', marginTop: -10}}>
          {formatNumber(Math.pow(2, WeaponMap[item.type].index), 1)}
        </Text>
      </Animated.View>
    </View>
  );
}

function getImageSource(item: InventoryItem) {
  return WeaponMap[item.type].image;
}

export function getSlotCoordinates(
  slot: number,
  layout: LayoutRectangle,
  size: InventorySize,
): {x: number; y: number} {
  const x = slot % size.columns;
  const y = Math.floor(slot / size.columns);
  const marginHorizontal = (layout.width - 16 - 75 * size.columns) / 2;
  return {
    x: layout.x + 20 + 75 * x + marginHorizontal,
    y: layout.y + 10 + 75 * y,
  };
}

function getSlotNumber(
  x: number,
  y: number,
  layout: LayoutRectangle,
  size: InventorySize,
): number {
  const marginHorizontal = (layout.width - 16 - 75 * size.columns) / 2;
  const xIndex = Math.round((x - marginHorizontal - 35 - (layout.x + 20)) / 75);
  const yIndex = Math.round((y - 35 - (layout.y + 10)) / 75);
  const index = yIndex * size.columns + xIndex;
  const inventorySize = size.columns * size.rows;
  if (index < 0) {
    return 0;
  }
  if (index >= inventorySize) {
    return inventorySize;
  }
  return index;
}

const isDropArea = (
  gesture: PanResponderGestureState,
  layout: LayoutRectangle,
): boolean => {
  console.log(gesture);
  return (
    gesture.moveY > layout.y &&
    gesture.moveY < layout.y + layout.height &&
    gesture.moveX > layout.x &&
    gesture.moveX < layout.x + layout.width
  );
};

export default function useAnimatedValue(
  initialValue: number,
  config?: any,
): Animated.Value {
  const ref = useRef<null | Animated.Value>(null);
  if (ref.current == null) {
    ref.current = new Animated.Value(initialValue, config);
  }
  return ref.current;
}
