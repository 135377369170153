import React, {useEffect, useState} from 'react';
import {Image, Modal, Pressable, StyleSheet, Text, View} from 'react-native';
import {Weapons} from '../core/Weapons';
import {useInventory} from '../state/state';
import {MenuItem} from './MenuItem';

const styles = StyleSheet.create({
  body: {alignItems: 'center', flexDirection: 'row'},
  closeButtonText: {fontSize: 18, fontWeight: 'bold', color: 'white'},
  closeButton: {padding: 10, marginRight: 10},
  imageContainer: {
    flex: 1,
    backgroundColor: 'black',
    marginLeft: 8,
    paddingTop: 8,
    borderRadius: 4,
    alignItems: 'center',
  },
  changeLevelButton: {padding: 30},
  previousText: {fontSize: 36, fontWeight: 'bold', color: 'white'},
  weaponLevelsText: {marginTop: 20, color: 'white', fontSize: 20},
  weaponImage: {
    width: 96,
    height: 96,
  },
  header: {flexDirection: 'row', paddingVertical: 8, width: '100%'},
  title: {
    flex: 1,
    alignSelf: 'center',
    marginLeft: 20,
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  backdrop: {
    height: 250,
    width: '80%',
    maxWidth: 400,
    backgroundColor: 'black',
    borderColor: 'white',
    borderWidth: 1,
  },
  backdropButton: {...StyleSheet.absoluteFillObject, zIndex: -1},
  modalContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  modal: {alignItems: 'center', justifyContent: 'center', flex: 1, zIndex: -1},
  hammerText: {fontSize: 14, fontWeight: '900', color: 'white'},
  hammerButton: {
    alignSelf: 'center',
    paddingHorizontal: 10,
    paddingVertical: 10,
    marginTop: 5,
    backgroundColor: '#333333',
    borderRadius: 8,
    borderColor: 'orange',
    borderWidth: 2,
    flex: 1,
    alignItems: 'center',
    marginHorizontal: 5,
  },
  container: {alignItems: 'center'},
});

export function Collection() {
  const discoveredWeaponLevel = useInventory(
    state => state.discoveredWeaponLevel,
  );
  const modalState = useInventory(state => state.modalState);
  const setModal = useInventory(state => state.setModal);

  const [activeLevel, setActiveLevel] = useState(discoveredWeaponLevel);

  const isCollectionVisible = modalState === 'collection';

  useEffect(() => {
    if (modalState) {
      setActiveLevel(discoveredWeaponLevel);
    }
  }, [modalState, discoveredWeaponLevel]);
  return (
    <>
      <MenuItem
        onPress={() => {
          setActiveLevel(discoveredWeaponLevel);
          setModal('collection');
        }}
        text={'Collection'}
      />

      <Modal
        transparent
        animationType="fade"
        visible={isCollectionVisible}
        style={styles.modal}>
        <View style={styles.modalContainer}>
          <Pressable
            onPress={() => {
              setModal('none');
            }}
            style={styles.backdropButton}
          />
          <View style={styles.backdrop}>
            <View style={styles.header}>
              <Text style={styles.title}>Collection</Text>
              <Pressable
                onPress={() => {
                  setModal('none');
                }}
                style={styles.closeButton}>
                <Text style={styles.closeButtonText}>X</Text>
              </Pressable>
            </View>
            <View style={styles.body}>
              <Pressable
                onPress={() => {
                  let previousLevel = (activeLevel - 1) % Weapons.length;
                  if (previousLevel < 0) {
                    previousLevel = Weapons.length - 1;
                  }
                  setActiveLevel(previousLevel);
                }}
                style={styles.changeLevelButton}>
                <Text style={styles.previousText}>{'<'}</Text>
              </Pressable>
              <View style={styles.imageContainer}>
                <Image
                  source={Weapons[activeLevel].image}
                  style={[
                    styles.weaponImage,
                    {
                      tintColor:
                        activeLevel > discoveredWeaponLevel
                          ? 'gray'
                          : undefined,
                    },
                  ]}
                />
                <Text style={styles.weaponLevelsText}>
                  {activeLevel + 1} / {Weapons.length}
                </Text>
              </View>
              <Pressable
                onPress={() => {
                  setActiveLevel((activeLevel + 1) % Weapons.length);
                }}
                style={styles.changeLevelButton}>
                <Text style={styles.previousText}>{'>'}</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
}
