import React from 'react';
import {StyleSheet, View} from 'react-native';

export function GoldIcon() {
  return (
    <View style={styles.gold}>
      <View style={styles.innerCircle} />
    </View>
  );
}

const styles = StyleSheet.create({
  innerCircle: {
    width: 14,
    height: 14,
    borderColor: 'yellow',
    borderWidth: 1,
    borderRadius: 10,
  },
  gold: {
    backgroundColor: 'gold',
    width: 20,
    height: 20,
    borderColor: 'lightgray',
    borderWidth: 1,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
