import {AppState} from 'react-native';
import {LayoutRectangle} from 'react-native/types';
import {create} from 'zustand';

import {AudioPlayer} from '../core/AudioPlayer';
import {
  getInventorySizeLevel,
  InventorySize,
  InventorySizes,
} from '../core/Inventory';
import {getNextWeapon, WeaponMap, Weapons, WeaponType} from '../core/Weapons';
import {Storage} from '../storage';
import {getCps} from '../utils/cps';
import {generateUUID} from '../utils/generateUUID';
import {Pricing} from '../utils/pricing';
import {isToday} from '../utils/isToday';
import {DailyRewards} from '../utils/dailyReward';

export type InventoryItem = {
  type: WeaponType;
  position: number;
  id: string;
};

type ModalState =
  | 'none'
  | 'upgrades'
  | 'collection'
  | 'dailyReward'
  | 'welcomeBack'
  | 'settings'
  | 'clover';

type IntentoryStateVariables = {
  items: InventoryItem[];
  layout: LayoutRectangle;
  size: InventorySize;
  modalState: ModalState;
  idleEarnings: number;
  point: number;
  cloverLeaves: number;
  discoveredWeaponLevel: number;
  newWeaponLevel: number;
  isBackgroundMusicEnabled: boolean;
  canShowAds: boolean;
  tutorialState: number;
  dailyRewardDay: number;
  dailyRewardLastDay?: string;
};
type InventoryStateFunctions = {
  upgrade: {
    upgradeItemForgeLevel: () => void;
    upgradeInventorySize: () => void;
  };
  claimIdleEarnings: (options?: {double?: boolean}) => void;
  setModal: (modal: ModalState) => void;
  addPoints: (amount: number) => void;
  addItem: (newItem: Pick<InventoryItem, 'type'>) => void;
  spawnItem: () => void;
  moveItem: (previousPosition: number, newPosition: number) => void;
  canMoveItem: (previousPosition: number, newPosition: number) => boolean;
  setLayout: (layout: LayoutRectangle) => void;
  setBackgroundMusicEnabled: (enabled: boolean) => void;
  reset: () => void;
  claimDailyReward: () => void;
};
type InventoryState = IntentoryStateVariables & InventoryStateFunctions;

const initialState: IntentoryStateVariables = {
  items: [
    {id: generateUUID(), position: 0, type: Weapons[0].type},
    {id: generateUUID(), position: 1, type: Weapons[0].type},
  ],
  point: 0,
  cloverLeaves: 0,
  size: {rows: 3, columns: 3},
  discoveredWeaponLevel: 0,
  newWeaponLevel: 0,
  modalState: 'none',
  idleEarnings: 0,
  layout: {x: 0, y: 0, height: 0, width: 0},
  isBackgroundMusicEnabled: true,
  canShowAds: false,
  tutorialState: 0,
  dailyRewardDay: 0,
  dailyRewardLastDay: undefined,
};

export const useInventory = create<InventoryState>((set, get) => ({
  ...initialState,
  upgrade: {
    upgradeItemForgeLevel: () => {
      const {point, newWeaponLevel, items} = get();
      const levelUpPrice = Pricing.itemForgeUpgradePrice(newWeaponLevel);
      if (point - levelUpPrice > 0) {
        const newItemType = Weapons[newWeaponLevel + 1].type;
        set({
          newWeaponLevel: newWeaponLevel + 1,
          point: point - levelUpPrice,
          items: items.map(i => {
            if (WeaponMap[i.type].index < newWeaponLevel + 1) {
              return {...i, type: newItemType};
            }
            return i;
          }),
        });
        AudioPlayer.playLevelComplete();
      }
    },
    upgradeInventorySize: () => {
      const {point, size} = get();
      const levelUpPrice = Pricing.inventorySizeUpgradePrice(size);
      const level = getInventorySizeLevel(size);
      if (point - levelUpPrice > 0 && level < InventorySizes.length - 1) {
        const newSize = InventorySizes[getInventorySizeLevel(size) + 1];
        set({
          point: point - levelUpPrice,
          size: newSize,
        });
        AudioPlayer.playLevelComplete();
      }
    },
  },
  setModal: modal => {
    set({modalState: modal});
  },
  spawnItem: () => {
    const {addItem, newWeaponLevel} = get();
    addItem({type: Weapons[newWeaponLevel].type});
  },
  claimIdleEarnings: options => {
    const {idleEarnings, addPoints} = get();
    addPoints(idleEarnings * (options?.double ? 2 : 1));
    set({idleEarnings: 0});
    Storage.setLastActive(undefined);
  },
  claimDailyReward: () => {
    const {dailyRewardDay, dailyRewardLastDay, items, point} = get();
    if (dailyRewardLastDay && isToday(dailyRewardLastDay)) {
      return;
    }
    AudioPlayer.playCoins();
    const cps = getCps(items);
    console.log();
    const reward = cps * DailyRewards[dailyRewardDay] * 60 * 60;
    set({
      dailyRewardDay: (dailyRewardDay + 1) % 7,
      dailyRewardLastDay: new Date().toISOString(),
      modalState: 'none',
      point: point + reward,
    });
  },
  addPoints: (amount: number) => set(state => ({point: state.point + amount})),
  addItem: (newItem: Pick<InventoryItem, 'type'>) => {
    const {items, size} = get();
    const inventorySize = size.columns * size.rows;

    if (items.length >= inventorySize) {
      return set({
        items: [
          ...items
            .filter(i => i.position <= inventorySize)
            .slice(0, size.columns * size.rows),
        ],
      });
    }
    const emptyIndex = items.findIndex((i, index) => i.position > index);
    if (emptyIndex === -1) {
      return set({
        items: [
          ...items,
          {
            ...newItem,
            position: items.length,
            id: generateUUID(),
          } as InventoryItem,
        ],
      });
    }
    set({
      items: [
        ...items.slice(0, emptyIndex),
        {
          ...newItem,
          position: emptyIndex,
          id: generateUUID(),
        } as InventoryItem,
        ...items.slice(emptyIndex),
      ],
    });
  },
  moveItem: (previousPosition: number, newPosition: number) => {
    const {items, discoveredWeaponLevel, cloverLeaves, tutorialState} = get();
    console.log('current', items);
    let newItems = [...items];
    const currentSlotIndex = items.findIndex(
      i => i.position === previousPosition,
    )!;
    const newSlotIndex = items.findIndex(i => i.position === newPosition);
    console.log({currentSlotIndex, newSlotIndex});
    if (newSlotIndex === -1) {
      const targetItemIndex = items.findIndex(
        i => i.position === previousPosition,
      );
      newItems[targetItemIndex] = {
        ...newItems[targetItemIndex],
        position: newPosition,
      };
      AudioPlayer.playReplaceItem();
      return set({items: newItems.sort((a, b) => a.position - b.position)});
    }

    // Upgrade (Merge)
    if (newItems[newSlotIndex].type === newItems[currentSlotIndex].type) {
      const rawChance = 40;
      let mergeResult =
        Math.random() * 100 <
        rawChance + cloverLeaves * ((100 - rawChance) / 4);

      const nextWeapon = getNextWeapon(newItems[newSlotIndex].type);
      if (!nextWeapon?.type) {
        return;
      }

      if (tutorialState < 3) {
        mergeResult = true;
        newItems[newSlotIndex] = {
          ...newItems[newSlotIndex],
          type: nextWeapon?.type,
        };

        if (tutorialState === 0) {
          newItems.push({
            ...newItems[currentSlotIndex],
            position: 2,
            id: generateUUID(),
          });
          newItems[currentSlotIndex] = {
            ...newItems[currentSlotIndex],
            id: generateUUID(),
          };
        } else {
          newItems = newItems.filter(i => i.position !== previousPosition);
        }
        let modalState = {};
        if (discoveredWeaponLevel < nextWeapon.index) {
          AudioPlayer.playLevelComplete();
          modalState = {modalState: 'collection'};
        } else {
          AudioPlayer.playAnvil();
        }
        return set({
          tutorialState: tutorialState + 1,
          cloverLeaves: 0,
          discoveredWeaponLevel: nextWeapon.index,
          items: newItems,
          ...modalState,
        });
      }

      if (!mergeResult) {
        AudioPlayer.playFire();
        return set({
          cloverLeaves: cloverLeaves + 1,
          items: newItems.filter(i => i.position !== previousPosition),
        });
      }
      newItems[newSlotIndex] = {
        ...newItems[newSlotIndex],
        type: nextWeapon?.type,
      };

      if (discoveredWeaponLevel < nextWeapon.index) {
        AudioPlayer.playLevelComplete();
        return set({
          cloverLeaves: 0,
          discoveredWeaponLevel: nextWeapon.index,
          modalState: 'collection',
          items: newItems.filter(i => i.position !== previousPosition),
        });
      } else {
        AudioPlayer.playAnvil();
        return set({
          cloverLeaves: 0,
          items: newItems.filter(i => i.position !== previousPosition),
        });
      }
    } else {
      // Replace
      newItems[currentSlotIndex] = {
        ...newItems[currentSlotIndex],
        position: newPosition,
      };

      newItems[newSlotIndex] = {
        ...newItems[newSlotIndex],
        position: previousPosition,
      };
      console.log('replace!', items);
      console.log(newItems.sort((a, b) => a.position - b.position));
      AudioPlayer.playReplaceItem();
      return set({items: newItems.sort((a, b) => a.position - b.position)});
    }
  },
  canMoveItem: (previousPosition: number, newPosition: number) => {
    const {items} = get();
    const currentSlotIndex = items.findIndex(
      i => i.position === previousPosition,
    )!;
    const newSlotIndex = items.findIndex(i => i.position === newPosition);
    if (newSlotIndex === -1) {
      return true;
    }
    if (items[newSlotIndex].type === items[currentSlotIndex].type) {
      return getNextWeapon(items[newSlotIndex].type) != null;
    }
    return true;
  },
  setLayout: (layout: LayoutRectangle) => {
    set({
      layout,
    });
  },
  setBackgroundMusicEnabled: enabled => {
    set({isBackgroundMusicEnabled: enabled});
  },
  reset: () => {
    const {layout, canShowAds, isBackgroundMusicEnabled} = get();

    set({...initialState, layout, canShowAds, isBackgroundMusicEnabled});
    Storage.reset();
  },
}));

if (Storage.getGlobalState()) {
  const state = JSON.parse(Storage.getGlobalState()!) as InventoryState;
  if (state.tutorialState === undefined) {
    state.tutorialState = 100;
  }
  handleWelcomeBackReward(state, true);
  AudioPlayer.init();
} else {
  AudioPlayer.init();
}

useInventory.subscribe(state => {
  const stateCopy = {...state};
  Object.keys(stateCopy).forEach(_key => {
    const key = _key as keyof typeof stateCopy;
    if (
      typeof stateCopy[key as keyof typeof stateCopy] === 'function' ||
      key === 'upgrade' ||
      key === 'modalState'
    ) {
      delete stateCopy[key as keyof typeof stateCopy];
    }
  });
  Storage.setGlobalState(JSON.stringify(stateCopy));
});

AppState.addEventListener('change', state => {
  console.log('state', state, new Date().toISOString());
  if (state === 'background') {
    Storage.setLastActive(new Date().toISOString());
    clearInterval(goldInterval);
  } else if (state === 'active') {
    clearInterval(goldInterval);
    setGoldInterval();
    const inventoryState = useInventory.getState();
    handleWelcomeBackReward(inventoryState, false);
  }
});

function handleWelcomeBackReward(
  inventoryState: InventoryState,
  forceSetState: boolean,
) {
  let idleTime = 0;
  try {
    const lastActive = new Date(
      Storage.getLastActive() || new Date().toISOString(),
    );
    idleTime = Date.now() - lastActive.getTime();
    console.log(lastActive.toISOString(), new Date().toISOString(), idleTime);
  } catch (err) {}
  const cps = getCps(inventoryState.items);
  const ONE_DAY = 24 * 60 * 60;
  const THIRTY_SECONDS = 30 * 1000;
  if (idleTime > THIRTY_SECONDS && cps > 0) {
    inventoryState.modalState = 'welcomeBack';
    inventoryState.idleEarnings =
      Math.min(idleTime / 1000, ONE_DAY) * cps * 0.4;
    useInventory.setState(inventoryState);
  } else if (forceSetState) {
    useInventory.setState(inventoryState);
  }
}

let goldInterval: number;
function setGoldInterval() {
  goldInterval = setInterval(() => {
    let cps = 0;
    useInventory.getState().items.forEach(a => {
      // console.log('WeaponMap[a.type].index;', WeaponMap[a.type].index);
      cps += Math.pow(2, WeaponMap[a.type].index);
    });
    useInventory.getState().addPoints(cps);
  }, 1000);
}

setGoldInterval();
