import React from 'react';
import {Modal, Pressable, StyleSheet, Text, View} from 'react-native';
import {useInventory} from '../../state/state';
import {Clover} from './Clover';

const styles = StyleSheet.create({
  percentage: {
    fontSize: 10,
    color: 'white',
    textAlign: 'center',
    fontWeight: '600',
  },
  icon: {width: 40, height: 40},
  backdropButton: {...StyleSheet.absoluteFillObject, zIndex: -1},
  overlay: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  modal: {alignItems: 'center', justifyContent: 'center', flex: 1},
  buttonBody: {flexDirection: 'row'},
  exclamationContainer: {
    backgroundColor: 'red',
    width: 16,
    height: 16,
    borderRadius: 8,
    alignItems: 'center',
    marginLeft: 4,
  },
  exclamationText: {color: 'white', fontSize: 14, fontWeight: '900'},
  hammerText: {fontSize: 18, fontWeight: '900', color: 'white'},
  hammerButton: {
    alignSelf: 'center',
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginTop: 5,
    backgroundColor: '#333333',
    borderRadius: 8,
    borderColor: 'orange',
    borderWidth: 2,
    flex: 1,
    alignItems: 'center',
    marginHorizontal: 10,
  },
  container: {alignItems: 'center'},
  row: {flexDirection: 'row', alignItems: 'center'},
});

export function CloverModal() {
  const modalState = useInventory(state => state.modalState);
  const setModal = useInventory(state => state.setModal);

  const isModalVisible = modalState === 'clover';

  return (
    <>
      <Modal
        transparent
        animationType="fade"
        visible={isModalVisible}
        style={styles.modal}>
        <View style={styles.overlay}>
          <Pressable
            onPress={() => {
              setModal('none');
            }}
            style={styles.backdropButton}
          />
          <View
            style={{
              height: 400,
              width: '80%',
              maxWidth: 400,
              backgroundColor: 'black',
              borderColor: 'white',
              borderWidth: 1,
            }}>
            <View
              style={{flexDirection: 'row', paddingVertical: 8, width: '100%'}}>
              <Text
                selectable={false}
                style={{
                  flex: 1,
                  alignSelf: 'center',
                  marginLeft: 20,
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: 'white',
                }}>
                Clover
              </Text>
              <Pressable
                onPress={() => {
                  setModal('none');
                }}
                style={{padding: 10, marginRight: 10}}>
                <Text
                  selectable={false}
                  style={{fontSize: 18, fontWeight: 'bold', color: 'white'}}>
                  X
                </Text>
              </Pressable>
            </View>
            <View style={{flex: 1}}>
              <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                <View>
                  <Clover leaves={0} />
                  <Text style={styles.percentage}>%40</Text>
                </View>
                <View>
                  <Clover leaves={1} />
                  <Text style={styles.percentage}>%55</Text>
                </View>
                <View>
                  <Clover leaves={2} />
                  <Text style={styles.percentage}>%70</Text>
                </View>
                <View>
                  <Clover leaves={3} />
                  <Text style={styles.percentage}>%85</Text>
                </View>
                <View>
                  <Clover leaves={4} />
                  <Text style={styles.percentage}>%100</Text>
                </View>
              </View>
              <Text
                style={{
                  fontSize: 16,
                  color: 'white',
                  margin: 24,
                }}>
                Merging weapons is a risky business as a weapon can be destroyed
                during the process. Every clover leaf increases your chances. If
                you are lucky enough to see a four-leaf clover, that's your
                moment to merge.
              </Text>
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
}
