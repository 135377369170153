import {ImageSourcePropType} from 'react-native';
export type Weapon = {
  type:
    | 'dagger'
    | 'lavaDagger'
    | 'broadSword'
    | 'lightDagger'
    | 'longDagger'
    | 'iceSword'
    | 'purpleSword'
    | 'axe'
    | 'redDagger'
    | 'blueDagger'
    | 'deathSword'
    | 'gladius'
    | 'goldenDagger'
    | 'greenDagger'
    | 'greenAxe'
    | 'skySword'
    | 'blueKnife'
    | 'lakeDagger'
    | 'forestDagger'
    | 'greenGladius'
    | 'redSword'
    | 'royalSword'
    | 'lionAxe';
  image: ImageSourcePropType;
};
export type WeaponType = Weapon['type'];

export const Weapons: Weapon[] = [
  {type: 'dagger', image: require('../assets/Dagger.png')},
  {type: 'longDagger', image: require('../assets/LongDagger.png')},
  {type: 'lightDagger', image: require('../assets/LightDagger.png')},
  {type: 'lavaDagger', image: require('../assets/LavaDagger.png')},
  {type: 'broadSword', image: require('../assets/BroadSword.png')},
  {type: 'iceSword', image: require('../assets/IceSword.png')},
  {type: 'purpleSword', image: require('../assets/PurpleSword.png')},
  {type: 'axe', image: require('../assets/Axe.png')},

  {type: 'redDagger', image: require('../assets/RedDagger.png')},
  {type: 'blueDagger', image: require('../assets/BlueDagger.png')},
  {type: 'deathSword', image: require('../assets/DeathSword.png')},
  {type: 'gladius', image: require('../assets/Gladius.png')},
  {type: 'greenDagger', image: require('../assets/GreenDagger.png')},
  {type: 'goldenDagger', image: require('../assets/GoldenDagger.png')},
  {type: 'skySword', image: require('../assets/SkySword.png')},
  {type: 'greenAxe', image: require('../assets/GreenAxe.png')},

  {type: 'redSword', image: require('../assets/RedSword.png')},
  {type: 'blueKnife', image: require('../assets/BlueKnife.png')},
  {type: 'greenGladius', image: require('../assets/GreenGladius.png')},
  {type: 'lakeDagger', image: require('../assets/LakeDagger.png')},
  {type: 'forestDagger', image: require('../assets/ForestDagger.png')},
  {type: 'royalSword', image: require('../assets/RoyalSword.png')},
  {type: 'lionAxe', image: require('../assets/LionAxe.png')},
];

export const WeaponMap = Weapons.reduce((arr, weapon, index) => {
  arr[weapon.type] = {...weapon, index};
  return arr;
}, {} as {[key in WeaponType]: Weapon & {index: number}});

export function getNextWeapon(
  type: WeaponType,
): (Weapon & {index: number}) | null {
  const index = WeaponMap[type].index;
  if (index >= Weapons.length - 1) {
    return null;
  }
  return WeaponMap[Weapons[index + 1].type];
}
