import {WeaponMap} from '../core/Weapons';
import {InventoryItem} from '../state/state';

export function getCps(items: InventoryItem[]) {
  let sum = 0;
  items.forEach(a => {
    sum += Math.pow(2, WeaponMap[a.type].index);
  });
  return sum;
}
