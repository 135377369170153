import React from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import {formatNumber} from '../../utils/formatNumber';

const styles = StyleSheet.create({
  upgradeOption: {flexDirection: 'row', marginBottom: 12},
  description: {fontSize: 12, color: 'white'},
  title: {fontSize: 16, color: 'white'},
  texts: {marginHorizontal: 8, flex: 1},
  icon: {width: 40, height: 40},
  levelText: {color: 'white', fontSize: 12},
  iconContainer: {
    backgroundColor: 'black',
    marginLeft: 8,
    paddingTop: 8,
    width: 65,
    height: 65,
    borderRadius: 4,
    alignItems: 'center',
  },
  upgradePrice: {fontSize: 12, color: 'black'},
  upgradeButtonText: {
    fontSize: 15,
    color: 'white',
    fontWeight: '900',
    textShadowColor: 'rgba(0, 0, 0, 0.75)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 2,
  },
});

export function UpgradeOption({
  title,
  description,
  image,
  level,
  maxLevel,
  hasEnoughGold,
  onUpgrade,
  price,
}: {
  title: string;
  image: React.ReactNode;
  description: string;
  level: number;
  maxLevel: number;
  hasEnoughGold: boolean;
  price: number;
  onUpgrade: () => void;
}) {
  const canUpgrade = hasEnoughGold && level < maxLevel;
  return (
    <View style={styles.upgradeOption}>
      <View style={styles.iconContainer}>
        {image}
        <Text style={styles.levelText}>Lvl {level}</Text>
      </View>
      <View style={styles.texts}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.description}>{description}</Text>
      </View>
      <Pressable
        disabled={!canUpgrade}
        onPress={onUpgrade}
        style={({pressed}) => [
          {
            opacity: pressed ? 0.7 : 1,
          },
          {
            backgroundColor: canUpgrade ? 'gold' : 'gray',
            width: 80,
            marginRight: 8,
            borderRadius: 8,
            alignItems: 'center',
            justifyContent: 'center',
          },
        ]}>
        <Text selectable={false} style={styles.upgradeButtonText}>
          {level < maxLevel ? 'Upgrade' : 'Max'}
        </Text>
        {level < maxLevel && (
          <View style={{}}>
            <Text selectable={false} style={styles.upgradePrice}>
              {formatNumber(price, 2)}
            </Text>
          </View>
        )}
      </Pressable>
    </View>
  );
}
