import React, {useCallback, useEffect} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  Easing,
  withRepeat,
  interpolate,
  runOnJS,
  withSequence,
} from 'react-native-reanimated';
import {AudioPlayer} from '../core/AudioPlayer';
import {useInventory} from '../state/state';

const styles = StyleSheet.create({
  tooltipRect: {borderRadius: 20, backgroundColor: 'white', padding: 10},
  tooltipText: {textAlign: 'center', color: 'black'},
  tooltip: {
    position: 'absolute',
    top: -45,
    width: 200,

    paddingVertical: 10,
    zIndex: 10,
    alignItems: 'center',
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 10,
    borderLeftWidth: 8,
    borderRightWidth: 8,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderTopColor: 'white',
  },
  hammerText: {fontSize: 30, fontWeight: '900', color: 'white'},
  hammerButton: {
    alignSelf: 'center',
    paddingHorizontal: 60,
    paddingVertical: 10,
    marginTop: 20,
    backgroundColor: '#333333',
    borderRadius: 18,
    borderColor: 'orange',
    borderWidth: 2,
  },
  container: {alignItems: 'center'},
  progressBar: {
    height: 10,
    width: 150,
    backgroundColor: 'lightgray',
    borderRadius: 5,
    overflow: 'hidden',
  },
  progress: {
    height: 10,
    width: '100%',
    backgroundColor: 'green',
    borderRadius: 5,
  },
});

const ProgressBar = () => {
  const spawnItem = useInventory(state => state.spawnItem);
  const tutorialState = useInventory(state => state.tutorialState);
  const shouldAnimate = tutorialState >= 3;
  const isInventoryFull = useInventory(
    state => state.items.length >= state.size.columns * state.size.rows,
  );

  const progress = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{translateX: interpolate(progress.value, [0, 1], [-150, 0])}],
  }));

  const loopAnimation = useCallback(() => {
    'worklet';
    if (shouldAnimate) {
      progress.value = withRepeat(
        withTiming(
          1,
          {
            duration: 5000,
            easing: Easing.linear,
          },
          completed => {
            if (completed) {
              console.log('completed!');
              runOnJS(spawnItem)();
            }
          },
        ),
        -1,
      );
    }
  }, [progress, spawnItem, shouldAnimate]);

  useEffect(() => {
    if (!isInventoryFull) {
      progress.value = 0;
      loopAnimation();
    } else {
      progress.value = 1;
    }
  }, [progress, loopAnimation, isInventoryFull]);

  const handlePress = useCallback(async () => {
    if (tutorialState === 3) {
      useInventory.setState({
        tutorialState: tutorialState + 1,
      });
    }
    if (isInventoryFull || !shouldAnimate) {
      return;
    }
    progress.value = Math.min(1, progress.value + 0.1);
    const speed = 1 - progress.value;
    AudioPlayer.playPop();
    progress.value = withTiming(
      1,
      {
        duration: 5000 * speed,
        easing: Easing.linear,
      },
      completed => {
        if (completed) {
          console.log('completed!');
          runOnJS(spawnItem)();
          progress.value = 0;
          loopAnimation();
        }
      },
    );
  }, [
    progress,
    isInventoryFull,
    shouldAnimate,
    spawnItem,
    loopAnimation,
    tutorialState,
  ]);

  return (
    <View style={styles.container}>
      {tutorialState === 3 && (
        <View style={styles.tooltip}>
          <View style={styles.tooltipRect}>
            <Text style={styles.tooltipText}>
              Press the Hammer to increase item forge speed
            </Text>
          </View>
          <View style={styles.triangle} />
        </View>
      )}
      <View style={styles.progressBar}>
        <Animated.View style={[styles.progress, animatedStyle]} />
      </View>
      <HammerButton onPress={handlePress} />
    </View>
  );
};

function HammerButton({onPress}: {onPress: () => void}) {
  const handlePress = () => {
    scale.value = withSequence(
      withTiming(1.2, {duration: 100}),
      withTiming(1, {duration: 100}),
    );
    onPress();
  };
  const scale = useSharedValue(1);
  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{scale: scale.value}],
  }));
  return (
    <Animated.View style={[animatedStyle]}>
      <Pressable onPress={handlePress} style={styles.hammerButton}>
        <Text selectable={false} style={styles.hammerText}>
          🔨
        </Text>
      </Pressable>
    </Animated.View>
  );
}

export default ProgressBar;
