import React, {useState} from 'react';
import {
  ActivityIndicator,
  Modal,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';

import {useInventory} from '../state/state';
import {formatNumber} from '../utils/formatNumber';
import {GoldIcon} from './GoldIcon';
import {Ads} from '../core/Ads';

const styles = StyleSheet.create({
  button: {
    borderColor: 'orange',
    borderWidth: 2,
    padding: 8,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  body: {
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
    margin: 16,
  },
  closeButtonText: {fontSize: 18, fontWeight: 'bold', color: 'white'},
  closeButton: {padding: 10, marginRight: 10},
  imageContainer: {
    flex: 1,
    backgroundColor: 'black',
    marginLeft: 8,
    paddingTop: 8,
    borderRadius: 4,
    alignItems: 'center',
  },
  changeLevelButton: {padding: 30},
  previousText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
  },
  weaponLevelsText: {marginTop: 20, color: 'white', fontSize: 20},
  weaponImage: {
    width: 96,
    height: 96,
  },
  header: {flexDirection: 'row', paddingVertical: 8, width: '100%'},
  title: {
    flex: 1,
    alignSelf: 'center',
    marginLeft: 20,
    fontSize: 20,
    fontWeight: 'bold',
    color: 'white',
  },
  backdrop: {
    height: 250,
    width: '80%',
    maxWidth: 400,
    backgroundColor: 'black',
    borderColor: 'white',
    borderWidth: 1,
  },
  backdropButton: {...StyleSheet.absoluteFillObject, zIndex: -1},
  modalContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  modal: {alignItems: 'center', justifyContent: 'center', flex: 1, zIndex: -1},
  hammerText: {fontSize: 18, fontWeight: '900', color: 'white'},
  hammerButton: {
    alignSelf: 'center',
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginTop: 5,
    backgroundColor: '#333333',
    borderRadius: 8,
    borderColor: 'orange',
    borderWidth: 2,
    flex: 1,
    alignItems: 'center',
    marginHorizontal: 10,
  },
  container: {alignItems: 'center'},
});

export function WelcomeBack() {
  const idleEarnings = useInventory(state => state.idleEarnings);
  const modalState = useInventory(state => state.modalState);
  const [adState, setAdState] = useState<'idle' | 'loading' | 'error'>('idle');
  const setModal = useInventory(state => state.setModal);
  const claimIdleEarnings = useInventory(state => state.claimIdleEarnings);

  const isVisible = modalState === 'welcomeBack';

  return (
    <>
      <Modal
        transparent
        animationType="fade"
        visible={isVisible}
        style={styles.modal}>
        <View style={styles.modalContainer}>
          <Pressable
            onPress={() => {
              setModal('none');
              claimIdleEarnings();
            }}
            style={styles.backdropButton}
          />
          <View style={styles.backdrop}>
            <View style={styles.body}>
              <View
                style={{marginTop: 40, height: 40, transform: [{scale: 2}]}}>
                <GoldIcon />
              </View>
              <Text
                style={{
                  color: 'white',
                  flex: 1,
                  fontSize: 18,
                  textAlign: 'center',
                  fontWeight: '400',
                }}>
                You've earned{' '}
                <Text style={{fontWeight: '600'}}>
                  {formatNumber(idleEarnings, 2)}
                </Text>{' '}
                coins while you were away
              </Text>
              <View style={{flexDirection: 'row', gap: 10, maxWidth: 250}}>
                <Pressable
                  onPress={() => {
                    setModal('none');
                    claimIdleEarnings();
                  }}
                  style={styles.button}>
                  <Text style={styles.previousText}>{'Okay'}</Text>
                </Pressable>
                {Platform.OS !== 'web' && (
                  <Pressable
                    disabled={adState === 'error'}
                    onPress={() => {
                      setAdState('loading');
                      Ads.requestRewardedAd({
                        onReward: () => {
                          claimIdleEarnings({double: true});
                        },
                        onError: _err => {
                          setAdState('error');
                        },
                        onClose: () => {
                          setModal('none');
                          setAdState('idle');
                        },
                      });
                    }}
                    style={[
                      styles.button,
                      {backgroundColor: 'orange'},
                      adState === 'error' && {
                        backgroundColor: 'gray',
                        borderColor: 'gray',
                        opacity: 0.5,
                      },
                    ]}>
                    {adState === 'idle' && (
                      <Text style={styles.previousText}>
                        {'Double It (Watch Ad)'}
                      </Text>
                    )}
                    {adState === 'loading' && (
                      <ActivityIndicator color={'white'} />
                    )}
                    {adState === 'error' && (
                      <Text style={styles.previousText}>
                        {'No Ad Available'}
                      </Text>
                    )}
                  </Pressable>
                )}
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
}
