import React from 'react';
import {
  Alert,
  Modal,
  Platform,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
} from 'react-native';

import {useInventory} from '../state/state';
import {AudioPlayer} from '../core/AudioPlayer';

const styles = StyleSheet.create({
  button: {
    borderColor: 'orange',
    borderWidth: 2,
    padding: 8,
    borderRadius: 8,
    alignItems: 'center',
  },
  body: {
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
    margin: 16,
  },
  closeButtonText: {fontSize: 18, fontWeight: 'bold', color: 'white'},
  closeButton: {padding: 10, marginRight: 10},
  imageContainer: {
    flex: 1,
    backgroundColor: 'black',
    marginLeft: 8,
    paddingTop: 8,
    borderRadius: 4,
    alignItems: 'center',
  },
  changeLevelButton: {padding: 30},
  previousText: {fontSize: 18, fontWeight: 'bold', color: 'white'},
  weaponLevelsText: {marginTop: 20, color: 'white', fontSize: 20},
  weaponImage: {
    width: 96,
    height: 96,
  },
  header: {flexDirection: 'row', paddingVertical: 8, width: '100%'},
  title: {
    alignSelf: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    color: 'white',
    marginTop: 12,
  },
  backdrop: {
    height: 250,
    width: '80%',
    maxWidth: 400,
    backgroundColor: 'black',
    borderColor: 'white',
    borderWidth: 1,
  },
  backdropButton: {...StyleSheet.absoluteFillObject, zIndex: -1},
  modalContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  modal: {alignItems: 'center', justifyContent: 'center', flex: 1, zIndex: -1},
  hammerText: {fontSize: 28, fontWeight: '900', color: 'white'},
  hammerButton: {backgroundColor: 'transparent', padding: 6, marginLeft: 8},
  container: {alignItems: 'center'},
});

export function Settings() {
  const modalState = useInventory(state => state.modalState);
  const isBackgroundMusicEnabled = useInventory(
    state => state.isBackgroundMusicEnabled,
  );

  const setModal = useInventory(state => state.setModal);
  const setBackgroundMusicEnabled = useInventory(
    state => state.setBackgroundMusicEnabled,
  );

  const reset = useInventory(state => state.reset);

  const isVisible = modalState === 'settings';

  return (
    <>
      <SafeAreaView style={{position: 'absolute', zIndex: 1}}>
        <Pressable
          onPress={() => {
            setModal('settings');
          }}
          style={styles.hammerButton}>
          <Text selectable={false} style={styles.hammerText}>
            ⚙️
          </Text>
        </Pressable>
      </SafeAreaView>
      <Modal
        transparent
        animationType="fade"
        visible={isVisible}
        style={styles.modal}>
        <View style={styles.modalContainer}>
          <Pressable
            onPress={() => {
              setModal('none');
            }}
            style={styles.backdropButton}
          />
          <View style={styles.backdrop}>
            <Text style={styles.title}>Settings</Text>

            <View style={styles.body}>
              <View style={{flexDirection: 'row', gap: 10, maxWidth: 250}}>
                <Pressable
                  onPress={() => {
                    if (Platform.OS === 'web') {
                      reset();
                      setModal('none');
                      return;
                    }
                    Alert.alert(
                      'Are you sure?',
                      "Your game progress will be reset. This can't be reversed.",
                      [
                        {
                          style: 'destructive',
                          text: 'Yes, Reset',
                          onPress: () => {
                            reset();
                            setModal('none');
                          },
                        },
                        {
                          style: 'cancel',
                          text: 'Cancel',
                        },
                      ],
                    );
                  }}
                  style={styles.button}>
                  <Text style={styles.previousText}>{'Reset Game'}</Text>
                </Pressable>
              </View>
              <Pressable
                onPress={() => {
                  const enabled = !isBackgroundMusicEnabled;
                  setBackgroundMusicEnabled(enabled);
                  AudioPlayer.isBackgroundMusicEnabled = enabled;

                  if (!enabled) {
                    AudioPlayer.isBackgroundMusicEnabled = false;
                    AudioPlayer.stopVillageTheme();
                  } else {
                    AudioPlayer.isBackgroundMusicEnabled = true;
                    AudioPlayer.playVillageTheme();
                  }
                }}
                style={[styles.button, {marginTop: 24}]}>
                <Text style={styles.previousText}>
                  Background Music: {isBackgroundMusicEnabled ? 'On' : 'Off'}
                </Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
}
