import {InventorySize} from '../core/Inventory';

class PricingClass {
  itemForgeUpgradePrice(currentLevel: number) {
    return 20000 * Math.pow(2, currentLevel);
  }
  inventorySizeUpgradePrice(size: InventorySize) {
    return Math.round(Math.pow(4, size.columns * size.rows));
  }
}

export const Pricing = new PricingClass();
