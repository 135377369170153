export type InventorySize = {rows: number; columns: number};

export const InventorySizes: InventorySize[] = [
  {
    rows: 3,
    columns: 3,
  },
  {
    rows: 3,
    columns: 4,
  },
  {
    rows: 4,
    columns: 4,
  },
  {
    rows: 4,
    columns: 5,
  },
];

export function getInventorySizeLevel(size: InventorySize) {
  return InventorySizes.findIndex(
    s => s.columns === size.columns && s.rows === size.rows,
  );
}
