import React from 'react';
import {Pressable, SafeAreaView, StyleSheet, Text, View} from 'react-native';
import {useInventory} from '../state/state';
import {getCps} from '../utils/cps';
import {formatNumber} from '../utils/formatNumber';
import {GoldIcon} from './GoldIcon';
import {Clover} from './Clover/Clover';

export function Header() {
  const points = useInventory(s => s.point);
  const cloverLeaves = useInventory(s => s.cloverLeaves);
  const setModal = useInventory(state => state.setModal);

  const cps = useInventory(s => getCps(s.items));
  return (
    <SafeAreaView style={styles.header}>
      <View style={styles.primaryContainer}>
        <Text style={styles.goldText}>
          {formatNumber(points, 3, points > 1000)}
        </Text>
        <GoldIcon />
      </View>
      <View style={styles.secondaryContainer}>
        <Text style={styles.goldPerSecondText}>{formatNumber(cps, 2)}</Text>
        <GoldIcon />
        <Text style={styles.perSecondText}>{' per second'}</Text>
      </View>
      <Pressable onPress={() => setModal('clover')}>
        <Clover leaves={cloverLeaves} />
      </Pressable>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  primaryContainer: {flexDirection: 'row', alignItems: 'center', gap: 4},
  secondaryContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    marginBottom: 4,
  },
  goldPerSecondText: {color: 'white', fontSize: 16, fontWeight: '700'},
  perSecondText: {color: 'white', fontSize: 16, fontWeight: '700'},
  goldText: {
    color: 'white',
    fontSize: 28,
    fontWeight: '700',
    fontVariant: ['tabular-nums'],
  },
  header: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8,
    minHeight: 72,
  },
});
