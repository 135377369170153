import React from 'react';
import {Heart} from './Heart';
import {View, StyleSheet} from 'react-native';

export function Clover({leaves}: {leaves: number}) {
  return (
    <View style={styles.container}>
      <Heart color={leaves > 0 ? '#40d127' : '#375432'} style={styles.up} />
      <Heart color={leaves > 1 ? '#40d127' : '#375432'} style={styles.left} />
      <Heart color={leaves > 2 ? '#40d127' : '#375432'} style={styles.right} />
      <Heart color={leaves > 3 ? '#40d127' : '#375432'} style={styles.bottom} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    width: 48,
    height: 48,
  },
  bottom: {
    position: 'absolute',
    top: 12,
    left: 0,
    transform: [{scale: 0.4}, {rotate: '180deg'}],
  },
  right: {
    position: 'absolute',
    top: 0,
    left: -12,
    transform: [{scale: 0.4}, {rotate: '270deg'}],
  },
  left: {
    position: 'absolute',
    top: 0,
    right: -14,
    transform: [{scale: 0.4}, {rotate: '90deg'}],
  },
  up: {
    position: 'absolute',
    top: -12,
    left: 0,
    transform: [{scale: 0.4}],
  },
});
