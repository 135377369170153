export function formatNumber(num: number, digits: number, strict?: boolean) {
  const lookup = [
    {value: 1, symbol: ''},
    {value: 1e3, symbol: 'k'},
    {value: 1e6, symbol: 'M'},
    {value: 1e9, symbol: 'G'},
    {value: 1e12, symbol: 'T'},
    {value: 1e15, symbol: 'P'},
    {value: 1e18, symbol: 'E'},
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (it) {
      return num >= it.value;
    });
  if (!item) {
    return 0;
  }
  let val = (num / item.value).toFixed(digits).replace(rx, '$1');
  if (strict && digits > 0 && val.indexOf('.') === -1) {
    val = val + '.';
  }
  while (val.split('.')?.[1]?.length < digits) {
    val = val + '0';
  }
  return val + item.symbol;
}
