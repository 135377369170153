import React, {useEffect} from 'react';
import {Modal, Pressable, StyleSheet, Text, View} from 'react-native';
import {useInventory} from '../state/state';
import {MenuItem} from './MenuItem';
import {GoldIcon} from './GoldIcon';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSequence,
  withTiming,
} from 'react-native-reanimated';
import {DailyRewards} from '../utils/dailyReward';
import {isToday} from '../utils/isToday';

const styles = StyleSheet.create({
  body: {
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 8,
  },
  closeButtonText: {fontSize: 18, fontWeight: 'bold', color: 'white'},
  closeButton: {padding: 10, marginRight: 10},
  imageContainer: {
    flex: 1,
    backgroundColor: 'black',
    marginLeft: 8,
    paddingTop: 8,
    borderRadius: 4,
    alignItems: 'center',
  },
  changeLevelButton: {padding: 30},
  buttonText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
  },
  weaponLevelsText: {marginTop: 20, color: 'white', fontSize: 20},
  weaponImage: {
    width: 96,
    height: 96,
  },
  header: {flexDirection: 'row', paddingVertical: 8, width: '100%'},
  title: {
    flex: 1,
    alignSelf: 'center',
    marginLeft: 20,
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  backdrop: {
    width: '80%',
    maxWidth: 400,
    backgroundColor: 'black',
    borderColor: 'white',
    borderWidth: 1,
    paddingBottom: 20,
  },
  backdropButton: {...StyleSheet.absoluteFillObject, zIndex: -1},
  modalContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  modal: {alignItems: 'center', justifyContent: 'center', flex: 1, zIndex: -1},
  hammerText: {fontSize: 14, fontWeight: '900', color: 'white'},
  hammerButton: {
    alignSelf: 'center',
    paddingHorizontal: 10,
    paddingVertical: 10,
    marginTop: 5,
    backgroundColor: '#333333',
    borderRadius: 8,
    borderColor: 'orange',
    borderWidth: 2,
    flex: 1,
    alignItems: 'center',
    marginHorizontal: 5,
  },
  container: {alignItems: 'center'},
});

export function DailyReward() {
  const dailyRewardDay = useInventory(state => state.dailyRewardDay);
  const claimDailyReward = useInventory(state => state.claimDailyReward);
  const dailyRewardLastDay = useInventory(state => state.dailyRewardLastDay);

  const isClaimedToday = isToday(dailyRewardLastDay);
  console.log('isClaimedToday', isClaimedToday, dailyRewardLastDay);
  const modalState = useInventory(state => state.modalState);
  const setModal = useInventory(state => state.setModal);

  const isVisible = modalState === 'dailyReward';

  return (
    <>
      <MenuItem
        onPress={() => {
          setModal('dailyReward');
        }}
        text="Reward"
        showExclamation={!isClaimedToday}
      />
      <Modal
        transparent
        animationType="fade"
        visible={isVisible}
        style={styles.modal}>
        <View style={styles.modalContainer}>
          <Pressable
            onPress={() => {
              setModal('none');
            }}
            style={styles.backdropButton}
          />
          <View style={styles.backdrop}>
            <View style={styles.header}>
              <Text style={styles.title}>Daily Reward</Text>
              <Pressable
                onPress={() => {
                  setModal('none');
                }}
                style={styles.closeButton}>
                <Text style={styles.closeButtonText}>X</Text>
              </Pressable>
            </View>
            <View style={styles.body}>
              {DailyRewards.map((hours, index) => {
                return (
                  <Day
                    key={index}
                    day={index + 1}
                    hours={hours}
                    claimed={
                      index < dailyRewardDay ||
                      (dailyRewardDay === 0 && isClaimedToday)
                    }
                    activeDay={index === dailyRewardDay && !isClaimedToday}
                  />
                );
              })}
            </View>
            <Pressable
              onPress={() => claimDailyReward()}
              disabled={isClaimedToday}
              style={({pressed}) => [
                {
                  marginTop: 20,

                  borderWidth: 2,
                  padding: 8,
                  borderRadius: 8,
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignSelf: 'center',
                  backgroundColor: isClaimedToday ? 'gray' : 'orange',
                  opacity: pressed ? 0.8 : 1,
                },
              ]}>
              <Text style={styles.buttonText}>{'Claim Reward'}</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    </>
  );
}

function Day({
  day,
  hours,
  claimed,
  activeDay,
}: {
  day: number;
  hours: number;
  claimed?: boolean;
  activeDay?: boolean;
}) {
  const offset = useSharedValue(0);

  const borderStyle = useAnimatedStyle(() => {
    return {
      borderColor: interpolateColor(offset.value, [0, 1], ['gold', 'gray']),
    };
  });

  useEffect(() => {
    offset.value = withRepeat(
      withSequence(
        withTiming(1, {duration: 500}),
        withTiming(0, {duration: 600}),
      ),
      -1,
    );
  }, [offset]);

  return (
    <Animated.View
      style={[
        {
          width: 90,
          height: 90,
          borderColor: activeDay ? 'gold' : claimed ? 'green' : 'gray',
          borderWidth: 1,
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: 4,
          opacity: activeDay || claimed ? 1 : 0.5,
        },
        activeDay && borderStyle,
      ]}>
      <Text style={{color: 'white', marginTop: 8, textAlign: 'center'}}>
        Day {day}
      </Text>
      {claimed ? (
        <Text
          style={{height: 30, marginVertical: 6, fontSize: 28, color: 'white'}}>
          ✅
        </Text>
      ) : (
        <View style={{height: 30, marginTop: 12, transform: [{scale: 1.5}]}}>
          <GoldIcon />
        </View>
      )}
      <Text style={{color: 'white', textAlign: 'center'}}>
        +{hours} {hours === 1 ? 'Hour' : 'Hours'}
      </Text>
    </Animated.View>
  );
}
