import React from 'react';
import {StyleSheet, useWindowDimensions, View} from 'react-native';
import {useInventory} from '../state/state';
import {Items} from './Items';
import {Slot} from './Slot';
import {Finger} from './Finger';

export function Inventory() {
  const tutorialState = useInventory(state => state.tutorialState);
  const setLayout = useInventory(state => state.setLayout);
  const {rows, columns} = useInventory(state => state.size);

  const {width, height} = useWindowDimensions();
  return (
    <>
      <View
        key={`${width}-${height}`}
        style={styles.container}
        onLayout={event => {
          setLayout(event.nativeEvent.layout);
          console.log('onLayout', event.nativeEvent.layout);
        }}>
        {Array.from({length: rows}).map((_, i) => (
          <View key={`row-${i}`} style={styles.row}>
            {Array.from({length: columns}).map((__, r) => (
              <Slot key={`column-${r}`} />
            ))}
          </View>
        ))}
      </View>
      <Items />
      {tutorialState < 3 && <Finger />}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: 390,
    alignSelf: 'center',
  },
  row: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginVertical: 2.5,
    gap: 5,
  },
});
