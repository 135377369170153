export const isToday = (date: string | undefined) => {
  if (!date) {
    return false;
  }
  const now = new Date();
  const thatDay = new Date(date);
  return (
    now.getFullYear() === thatDay.getFullYear() &&
    now.getMonth() === thatDay.getMonth() &&
    now.getDate() === thatDay.getDate()
  );
};
