import React, {useState} from 'react';
import {Image, Modal, Pressable, StyleSheet, Text, View} from 'react-native';
import {getInventorySizeLevel} from '../../core/Inventory';
import {Weapons} from '../../core/Weapons';
import {useInventory} from '../../state/state';
import {Pricing} from '../../utils/pricing';
import {InventorySizeIcon} from './InventorySizeIcon';
import {UpgradeOption} from './UpgradeOption';
import {MenuItem} from '../MenuItem';

const styles = StyleSheet.create({
  icon: {width: 40, height: 40},
  backdropButton: {...StyleSheet.absoluteFillObject, zIndex: -1},
  overlay: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  modal: {alignItems: 'center', justifyContent: 'center', flex: 1},
  buttonBody: {flexDirection: 'row'},
  exclamationContainer: {
    backgroundColor: 'red',
    width: 16,
    height: 16,
    borderRadius: 8,
    alignItems: 'center',
    marginLeft: 4,
  },
  exclamationText: {color: 'white', fontSize: 14, fontWeight: '900'},
  hammerText: {fontSize: 14, fontWeight: '900', color: 'white'},
  hammerButton: {
    alignSelf: 'center',
    paddingHorizontal: 10,
    paddingVertical: 10,
    marginTop: 5,
    backgroundColor: '#333333',
    borderRadius: 8,
    borderColor: 'orange',
    borderWidth: 2,
    flex: 1,
    alignItems: 'center',
    marginHorizontal: 5,
  },
  container: {alignItems: 'center'},
  row: {flexDirection: 'row', alignItems: 'center'},
});

export function Upgrade() {
  const upgradeItemForgeLevel = useInventory(
    state => state.upgrade.upgradeItemForgeLevel,
  );
  const upgradeInventorySize = useInventory(
    state => state.upgrade.upgradeInventorySize,
  );
  const point = useInventory(state => state.point);
  const newWeaponLevel = useInventory(state => state.newWeaponLevel);
  const size = useInventory(state => state.size);

  const [isUpgradeVisible, setIsUpgradeVisible] = useState(false);

  const forgePrice = Pricing.itemForgeUpgradePrice(newWeaponLevel);
  const canUpgradeForgeLevel = point > forgePrice;

  const sizePrice = Pricing.inventorySizeUpgradePrice(size);
  const sizeLevel = getInventorySizeLevel(size) + 1;
  const maxSizeLevel = 4;
  const canUpgradeSizeLevel = point > sizePrice && sizeLevel < maxSizeLevel;

  const hasUpgrade = canUpgradeForgeLevel || canUpgradeSizeLevel;
  return (
    <>
      <MenuItem
        onPress={() => {
          setIsUpgradeVisible(true);
        }}
        text="Upgrade"
        showExclamation={hasUpgrade}
      />
      <Modal
        transparent
        animationType="fade"
        visible={isUpgradeVisible}
        style={styles.modal}>
        <View style={styles.overlay}>
          <Pressable
            onPress={() => {
              setIsUpgradeVisible(false);
            }}
            style={styles.backdropButton}
          />
          <View
            style={{
              height: 400,
              width: '80%',
              maxWidth: 400,
              backgroundColor: 'black',
              borderColor: 'white',
              borderWidth: 1,
            }}>
            <View
              style={{flexDirection: 'row', paddingVertical: 8, width: '100%'}}>
              <Text
                selectable={false}
                style={{
                  flex: 1,
                  alignSelf: 'center',
                  marginLeft: 20,
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: 'white',
                }}>
                Upgrade
              </Text>
              <Pressable
                onPress={() => {
                  setIsUpgradeVisible(false);
                }}
                style={{padding: 10, marginRight: 10}}>
                <Text
                  selectable={false}
                  style={{fontSize: 18, fontWeight: 'bold', color: 'white'}}>
                  X
                </Text>
              </Pressable>
            </View>
            <UpgradeOption
              title={'Item forge level'}
              image={
                <Image
                  source={Weapons[newWeaponLevel].image}
                  style={styles.icon}
                />
              }
              description={`New items spawn at level ${newWeaponLevel + 1}`}
              level={newWeaponLevel + 1}
              maxLevel={Weapons.length - 1}
              hasEnoughGold={canUpgradeForgeLevel}
              onUpgrade={upgradeItemForgeLevel}
              price={forgePrice}
            />
            <UpgradeOption
              title={'Inventory size'}
              image={<InventorySizeIcon size={size} />}
              description={`Inventory can hold ${
                size.rows * size.columns
              } items`}
              level={sizeLevel}
              maxLevel={maxSizeLevel}
              hasEnoughGold={canUpgradeSizeLevel}
              onUpgrade={upgradeInventorySize}
              price={sizePrice}
            />
          </View>
        </View>
      </Modal>
    </>
  );
}
