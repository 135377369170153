import React from 'react';
import {View, ViewStyle, StyleSheet} from 'react-native';

export function Heart({color, style}: {color?: string; style?: ViewStyle}) {
  const backgroundColor = color || '#40d127';
  return (
    <View style={[styles.heart, style]}>
      <View style={[styles.heartShape, styles.leftHeart, {backgroundColor}]} />
      <View style={[styles.heartShape, styles.rightHeart, {backgroundColor}]} />
    </View>
  );
}

const styles = StyleSheet.create({
  heart: {
    width: 50,
    height: 50,
  },
  heartShape: {
    width: 30,
    height: 45,
    position: 'absolute',
    top: 0,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    backgroundColor: '#40d127',
  },
  leftHeart: {
    transform: [{rotate: '-45deg'}],
    left: 5,
  },
  rightHeart: {
    transform: [{rotate: '45deg'}],
    right: 5,
  },
});
