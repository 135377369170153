import {Audio} from 'expo-av';
import {AppState} from 'react-native';
import {useInventory} from '../state/state';

class AudioPlayerImpl {
  anvil: Audio.SoundObject | undefined;
  coins: Audio.SoundObject | undefined;
  levelComplete: Audio.SoundObject | undefined;
  replaceItem: Audio.SoundObject | undefined;
  pop: Audio.SoundObject | undefined;
  fire: Audio.SoundObject | undefined;
  villageTheme: Audio.SoundObject | undefined;
  isPlayingBackgroundMusic: boolean = false;
  isBackgroundMusicEnabled: boolean = false;

  async init() {
    await Audio.setAudioModeAsync({
      playsInSilentModeIOS: true,
      staysActiveInBackground: false,
    });
    this.anvil = await Audio.Sound.createAsync(
      require('../assets/sounds/anvil.mp3'),
    );
    this.coins = await Audio.Sound.createAsync(
      require('../assets/sounds/coins.mp3'),
    );
    this.levelComplete = await Audio.Sound.createAsync(
      require('../assets/sounds/levelComplete.mp3'),
    );
    this.replaceItem = await Audio.Sound.createAsync(
      require('../assets/sounds/itemReplace.mp3'),
    );
    this.pop = await Audio.Sound.createAsync(
      require('../assets/sounds/pop.mp3'),
    );
    this.fire = await Audio.Sound.createAsync(
      require('../assets/sounds/fire.mp3'),
    );
    this.villageTheme = await Audio.Sound.createAsync(
      require('../assets/sounds/villageTheme.mp3'),
    );
    this.isBackgroundMusicEnabled =
      useInventory.getState().isBackgroundMusicEnabled;
    if (this.isBackgroundMusicEnabled) {
      this.playVillageTheme();
    }
    AppState.addEventListener('change', state => {
      if (state === 'background') {
        this.villageTheme?.sound.stopAsync();
        this.isPlayingBackgroundMusic = false;
      } else if (
        state === 'active' &&
        !this.isPlayingBackgroundMusic &&
        this.isBackgroundMusicEnabled
      ) {
        this.playVillageTheme();
      }
    });
  }

  playAnvil() {
    this.anvil!.sound.replayAsync({isMuted: false}).catch(() => {
      console.log('failed to play!');
    });
    if (!this.isPlayingBackgroundMusic && this.isBackgroundMusicEnabled) {
      this.playVillageTheme();
    }
  }

  playCoins() {
    this.coins!.sound.replayAsync().catch(() => {
      console.log('failed to play!');
    });
  }

  playLevelComplete() {
    this.levelComplete!.sound.replayAsync({isMuted: false}).catch(() => {
      console.log('failed to play!');
    });
  }

  playReplaceItem() {
    this.replaceItem!.sound.replayAsync({isMuted: false}).catch(() => {
      console.log('failed to play!');
    });
  }

  playFire() {
    this.fire!.sound.replayAsync({isMuted: false}).catch(() => {
      console.log('failed to play!');
    });
  }

  playPop() {
    this.pop!.sound.replayAsync().catch(() => {
      console.log('failed to play!');
    });
    if (!this.isPlayingBackgroundMusic && this.isBackgroundMusicEnabled) {
      this.playVillageTheme();

      this.replaceItem!.sound.replayAsync({isMuted: true}).catch(() => {
        console.log('failed to play!');
      });
      this.anvil!.sound.replayAsync({isMuted: true}).catch(() => {
        console.log('failed to play!');
      });
      this.levelComplete!.sound.replayAsync({isMuted: true}).catch(() => {
        console.log('failed to play!');
      });
      this.fire!.sound.replayAsync({isMuted: true}).catch(() => {
        console.log('failed to play!');
      });
    }
  }

  playVillageTheme() {
    this.isPlayingBackgroundMusic = true;
    this.villageTheme?.sound
      .replayAsync({isLooping: true})
      .then(() => {
        this.isPlayingBackgroundMusic = true;
      })
      .catch(() => {
        console.log('failed to play!');
        this.isPlayingBackgroundMusic = false;
      });
  }

  stopVillageTheme() {
    this.villageTheme?.sound.stopAsync().then(() => {
      this.isPlayingBackgroundMusic = false;
    });
  }
}

export const AudioPlayer = new AudioPlayerImpl();
