import {MMKV} from 'react-native-mmkv';

const storage = new MMKV();

const globalStateKey = 'globalState';

const lastActiveKey = 'lastActive';

class StorageImpl {
  getGlobalState() {
    return storage.getString(globalStateKey);
  }

  setGlobalState(state: string) {
    return storage.set(globalStateKey, state);
  }

  getLastActive() {
    return storage.getString(lastActiveKey);
  }

  setLastActive(lastActive: string | undefined) {
    if (!lastActive) {
      return storage.delete(lastActiveKey);
    }
    return storage.set(lastActiveKey, lastActive);
  }
  reset() {
    storage.clearAll();
  }
}

export const Storage = new StorageImpl();
